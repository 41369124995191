export default class Navigation {

    constructor() {

		this.url = document.body.dataset.url;

		this.setupListeners();
		this.setupEmailLinks();
		this.initializeState();

    }

	initializeState() {
        const page = document.body.classList.contains('page-feed') ? 'page-feed' : 'page-information';
        let url = (page === 'page-feed') ? this.url : `${this.url}/information`;
        history.replaceState({ page: page }, '', url);
    }


    setupListeners() {
        document.querySelectorAll('main > header a').forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                const page = link.classList.contains('feed-link') ? 'page-feed' : 'page-information';
                this.changePage(page);
            });
        });

        window.addEventListener('popstate', (event) => {
            if (event.state && event.state.page) {
                this.changePage(event.state.page, false);
            }
        });

        window.addEventListener('pageshow', this.handlePageShow.bind(this));
    }

    changePage(page, pushState = true) {
        if (document.body.classList.contains(page)) return;

        this.updatePageClasses(page);
        this.updateActiveLink(page);
        this.updateHistory(page, pushState);
    }

    updatePageClasses(page) {
        document.body.classList.remove('page-feed', 'page-information');
        document.body.classList.add(page);
    }

    updateActiveLink(page) {
        const allLinks = document.querySelectorAll('.feed-link, .information-link');
        allLinks.forEach(link => link.classList.remove('active-link'));

        if (page === 'page-feed') {
            document.querySelectorAll('.feed-link').forEach(link => link.classList.add('active-link'));
        } else if (page === 'page-information') {
            document.querySelectorAll('.information-link').forEach(link => link.classList.add('active-link'));
        }
    }

    updateHistory(page, pushState) {
        if (pushState) {
            let url = (page === 'page-feed') ? this.url : `${this.url}/information`;
            history.pushState({ page: page }, '', url);
        }
    }

    handlePageShow(event) {
        if (event.persisted) {
            window.location.reload();
        }
    }

	setupEmailLinks() {
		document.querySelectorAll('a[href^="mailto:"]').forEach(link => {
			link.addEventListener('click', function(event) {
				if (!this.classList.contains('has-clicked')) {

					event.preventDefault();
					this.classList.add('has-clicked');
	
					const email = this.getAttribute('href').replace('mailto:', '');
					
					navigator.clipboard.writeText(email)
						.then(() => {
							console.log('Email address copied to clipboard');
							
							const originalText = this.textContent;
							this.textContent = 'copied to clipboard';
	
							setTimeout(() => {
								this.textContent = originalText;
							}, 3000);
						})
						.catch(err => {
							console.error('Error in copying text: ', err);
						});
				}
			});
		});
	}
	
}
