import Isotope from 'isotope-layout';
import Packery from 'isotope-packery'
import Filters from './Filters';

export default class Layout {
    constructor() {
        this.url = document.body.dataset.url;
        this.filters = new Filters(this.setupIsotope());
    }

    setupIsotope() {
        const projects = document.querySelector('.projects');
        const iso = new Isotope(projects, {
            itemSelector: 'article',
            layoutMode: 'packery',
            hiddenStyle: { opacity: 0 },
            visibleStyle: { opacity: 1 },
            transitionDuration: 0
        });
        document.querySelector('.projects').classList.remove('unloaded');
        return iso;
    }
}