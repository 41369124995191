export default class Filters {
	
    constructor(isotopeInstance) {
        this.iso = isotopeInstance;
        this.setupFilters();
    }

    setupFilters() {
        const filters = document.querySelectorAll('.filter-button');
    
        filters.forEach(button => {
            button.addEventListener('click', () => {
                this.handleFilterClick(button, filters);
            });
        });
    }

    handleFilterClick(button, filters) {
        const isActive = button.classList.contains('active-filter');
        filters.forEach(btn => btn.classList.remove('active-filter'));
        
        if (!isActive) {
            button.classList.add('active-filter');
            this.applyIsotopeFilter(button);
        } else {
            this.iso.arrange({ filter: '*' });
        }

        if(window.innerWidth < 768) return;
        this.scrollToProjects();
    }

    applyIsotopeFilter(button) {
        const filterValue = button.getAttribute('data-filter').trim();
        this.iso.arrange({
            filter: itemElem => {
                const categories = itemElem.getAttribute('data-categories');
                if (categories) {
                    const categoryArray = categories.split(',').map(category => category.trim());
                    return filterValue === '*' || categoryArray.includes(filterValue);
                }
                return false;
            }
        });
    }

    scrollToProjects() {
        const projectsElement = document.querySelector('.projects');
        if (projectsElement) {
            const spacer = window.getComputedStyle(projectsElement).paddingLeft;
            const topPos = projectsElement.getBoundingClientRect().top + window.pageYOffset - parseInt(spacer, 10);

            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            });
        }
    }
}
