import Flickity from 'flickity';
import flickityFade from 'flickity-fade';

export default class Testimonials {
	
    constructor() {
        const elem = document.querySelector('.testimonials');
		const testimonials = new Flickity( elem, {
			cellSelector: 'li',
			cellAlign: 'left',
			contain: true,
			prevNextButtons: false,
			pageDots: false,
			fade: true,
			adaptiveHeight: true,
			autoPlay: 3000,
			wrapAround: true
		});

		
		let testimonialsHeight = 0;

		testimonials.on('change', function(index) {
			document.querySelector('.current-index').textContent = index + 1;
			// const newTestimonialsHeight = parseInt(document.querySelector('.testimonials .flickity-viewport').clientHeight);

			// testimonialsHeight = testimonialsHeight > newTestimonialsHeight ? testimonialsHeight : newTestimonialsHeight;
			// document.querySelector('.testimonials').style.height = testimonialsHeight + 'px';
		});
		
		document.querySelector('.next-testimonial').addEventListener('click', function() {
			testimonials.next();
		});
    }

	

}
