import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import Packery from 'isotope-packery'
import ColorThief from 'colorthief';
import Filters from './Filters';


export default class Media {

    constructor() {
        this.initialize();
    }

	initialize() {
        this.setupLazyLoad();
        this.setupPlayPauseVideos();
        this.setupConnectedMediaInteractions();
    }

    setupLazyLoad() {
        const media = document.querySelectorAll('img[data-src], video[data-src]');
        const observer = new IntersectionObserver(this.onMediaIntersection.bind(this), {
            rootMargin: '100%', // Extends the top margin of the viewport
            threshold: 0.01
        });

        media.forEach(el => observer.observe(el));
    }

    onMediaIntersection(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting && !entry.target.src) {
                entry.target.tagName === 'VIDEO' ? this.loadVideo(entry.target) : this.loadImage(entry.target);
                observer.unobserve(entry.target);
            }
        });
    }

    loadImage(img) {
        img.src = img.dataset.src;
        imagesLoaded(img, () => {
            const hue = this.getColor(img);
            img.closest('article').style.backgroundColor = `hsl(${hue}, 100%, 25%)`;
            img.closest('article').classList.remove('unloaded');
        });
    }

    loadVideo(video) {
        video.src = video.dataset.src;
        video.load();
        video.closest('article').style.backgroundColor = `hsl(0, 0%, 0%)`;
        video.closest('article').classList.remove('unloaded');
    }

    setupPlayPauseVideos() {
        const videos = document.querySelectorAll('video');
        const observer = new IntersectionObserver(this.handleVideoVisibility.bind(this), {
            root: null,
            rootMargin: '0px',
            threshold: 0.3
        });

        videos.forEach(video => observer.observe(video));
    }

    handleVideoVisibility(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.attemptToPlayVideo(entry.target);
            } else {
                entry.target.pause();
            }
        });
    }

    attemptToPlayVideo(video) {
        if (video.readyState >= 3) {
            video.play().catch(console.error);
        } else {
            video.addEventListener('canplaythrough', () => {
                video.play().catch(console.error);
            }, { once: true });
        }
    }

	setupConnectedMediaInteractions() {
		
		const articles = document.querySelectorAll('.feed-container article');
		let hoverTimeout; 

        if(window.innerWidth < 768) return;
        
		articles.forEach(article => {
			article.addEventListener('mouseenter', (event) => {
				clearTimeout(hoverTimeout);

				article.classList.add('hovering');

				hoverTimeout = setTimeout(() => {
					const title = article.getAttribute('data-title');
					articles.forEach(otherArticle => {
						if (otherArticle.getAttribute('data-title') !== title) {
							otherArticle.classList.add('unconnected');
						}
					});
				}, 1000);
			});

			article.addEventListener('mouseleave', () => {
				clearTimeout(hoverTimeout);
				article.classList.remove('hovering');
				articles.forEach(otherArticle => {
					otherArticle.classList.remove('unconnected');
				});
			});
		});

	}

	getColor(img) {
        const colorThief = new ColorThief();
        const rgb = colorThief.getColor(img);
        return this.rgbToHue(rgb[0], rgb[1], rgb[2]);
    }

	rgbToHue(r,g,b) {
		r /= 255;
		g /= 255;
		b /= 255;
	
		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let hue;
		const delta = max - min;
	
		if (delta === 0) { // This is a gray, no chroma
			hue = 0; // Hue is technically undefined, set it to 0
		} else {
			switch (max) {
				case r: // Red is max
					hue = ((g - b) / delta) % 6;
					break;
				case g: // Green is max
					hue = (b - r) / delta + 2;
					break;
				case b: // Blue is max
					hue = (r - g) / delta + 4;
					break;
			}
	
			hue *= 60; // Convert to degrees on the color wheel
			if (hue < 0) hue += 360; // Make sure hue is not negative
		}
	
		return hue; // Returns hue value (0 - 360)
	}
}